<template>
  <div class="bg-white">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form>
        <div class="p-l-10 app-title">Dăng ký</div>
        <table
          class="table dataTable app-table-form app-colspan app-colspan-10"
        >
          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Input <span class="app-require">＊</span>
            </th>
            <td>
              <AppInput
                name="name"
                type="string"
                rules="required"
                v-model="testInput"
              />
            </td>
          </tr>
          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Text <span class="app-require">＊</span>
            </th>
            <td>
              <div class="app-gr-control">
                <select ref="select" class="form-control">
                  <option value="AZ">Arizona</option>
                  <option value="CO">Colorado</option>
                  <option value="ID">Idaho</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NM">New Mexico</option>
                  <option value="ND">North Dakota</option>
                  <option value="UT">Utah</option>
                  <option value="WY">Wyoming</option>
                </select>
                <label id="firstName-error" class="error" for="firstName">
                  This field is required.
                </label>
              </div>
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Select input <span class="app-require">＊</span>
            </th>
            <td>
              <AppSelect
                :options-data="[{ id: ROLES.ADMIN, name: $t('users.admin') }]"
                v-model="testSelect"
                placeholder="test"
              />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Radio block <span class="app-require">＊</span>
            </th>
            <td>
              <app-radio label="Option 1" val="opton 1" v-model="radio.block" />
              <app-radio
                label="Option 2"
                val="option 2"
                v-model="radio.block"
              />
              <app-radio
                label="Option 3"
                val="option 3"
                v-model="radio.block"
              />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Radio inline <span class="app-require">＊</span>
            </th>
            <td>
              <app-radio
                class="app-inline"
                label="Option 1"
                val="opton 1"
                v-model="radio.inline"
              />
              <app-radio
                class="app-inline"
                label="Option 2"
                val="option 2"
                v-model="radio.inline"
              />
              <app-radio
                class="app-inline"
                label="Option 3"
                val="option 3"
                v-model="radio.inline"
              />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Radio input right <span class="app-require">＊</span>
            </th>
            <td>
              <app-radio
                class="app-inline right"
                label="Option 1"
                val="opton 1"
                v-model="radio.right"
              />
              <app-radio
                class="app-inline right"
                label="Option 2"
                val="option 2"
                v-model="radio.right"
              />
              <app-radio
                class="app-inline right"
                label="Option 3"
                val="option 3"
                v-model="radio.right"
              />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Checkbox block <span class="app-require">＊</span>
            </th>
            <td>
              <app-checkbox
                label="Option 1"
                val="opton 1"
                v-model="checkbox.block"
              />
              <app-checkbox
                label="Option 2"
                val="option 2"
                v-model="checkbox.block"
              />
              <app-checkbox
                label="Option 3"
                val="option 3"
                v-model="checkbox.block"
              />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Checkbox inline <span class="app-require">＊</span>
            </th>
            <td>
              <app-checkbox
                class="app-inline"
                label="Option 1"
                val="opton 1"
                v-model="checkbox.inline"
              />
              <app-checkbox
                class="app-inline"
                label="Option 2"
                val="option 2"
                v-model="checkbox.inline"
              />
              <app-checkbox
                class="app-inline"
                label="Option 3"
                val="option 3"
                v-model="checkbox.inline"
              />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Checkbox circle <span class="app-require">＊</span>
            </th>
            <td>
              <app-checkbox
                class="app-inline checkbox-circle"
                label="Option 1"
                val="opton 1"
                v-model="checkbox.circle"
              />
              <app-checkbox
                class="app-inline checkbox-circle"
                label="Option 2"
                val="option 2"
                v-model="checkbox.circle"
              />
              <app-checkbox
                class="app-inline checkbox-circle"
                label="Option 3"
                val="option 3"
                v-model="checkbox.circle"
              />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Checkbox input right <span class="app-require">＊</span>
            </th>
            <td>
              <app-checkbox
                class="app-inline right"
                label="Option 1"
                val="opton 1"
                v-model="checkbox.right"
              />
              <app-checkbox
                class="app-inline right"
                label="Option 2"
                val="option 2"
                v-model="checkbox.right"
              />
              <app-checkbox
                class="app-inline right"
                label="Option 3"
                val="option 3"
                v-model="checkbox.right"
              />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Text area <span class="app-require">＊</span>
            </th>
            <td>
              <app-textarea />
            </td>
          </tr>

          <tr>
            <th class="app-label app-align-middle app-w-200 p-l-10">
              Date picker <span class="app-require">＊</span>
            </th>
            <td>
              <div class="app-w-200">
                <app-datepicker></app-datepicker>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="p-t-20">
              <div class="form-group">
                <button class="app-btn app-btn-blue m-r-10">Search</button>
                <button class="app-btn app-btn-orange">Reset</button>
              </div>
            </td>
          </tr>
        </table>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import AppInput from "../../components/forms/AppInput.vue";
import AppSelect from "../../components/forms/AppSelect.vue";
import AppRadio from "../../components/forms/AppRadio.vue";
import AppCheckbox from "../../components/forms/AppCheckbox.vue";
import AppTextarea from "../../components/forms/AppTextarea.vue";
import AppDatepicker from "../../components/forms/AppDatepicker.vue";
export default {
  name: "FormPage",

  components: {
    AppInput,
    AppSelect,
    "app-radio": AppRadio,
    "app-checkbox": AppCheckbox,
    "app-textarea": AppTextarea,
    "app-datepicker": AppDatepicker,
  },

  data() {
    return {
      testInput: "",
      testSelect: "",
      radio: {
        block: "opton 1",
        inline: "",
        right: "",
      },
      checkbox: {
        block: ["opton 1", "option 2"],
        inline: [],
        circle: [],
        right: [],
      },
    };
  },

  mounted: function () {
    this.initSelect();
  },

  methods: {
    initSelect() {
      $(this.$refs.select).val(this.value);
      $(this.$refs.select).select2();
    },

    handleTestChange(e) {
      console.log(e.target.value);
    },
  },
};
</script>
